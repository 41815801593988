// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN ?? process.env.NEXT_PUBLIC_SENTRY_DSN

const ENV = process.env.VERCEL_ENV ?? process.env.NEXT_PUBLIC_VERCEL_ENV

Sentry.init({
  dsn: SENTRY_DSN || 'https://b9c1210166b249399b07758fd2494405@o58364.ingest.sentry.io/1370620',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: ENV === 'production' ? 0.1 : 1.0,
  environment: ENV,
  allowUrls: [
    /https?:\/\/([^./]+\.)*outwrite\.com/,
    ...process.env.NEXT_PUBLIC_VERCEL_URL
      ? [new RegExp(`https?://${process.env.NEXT_PUBLIC_VERCEL_URL}`)]
      : [],
    // Allow analytics
    /https?:\/\/www\.google-analytics\.com/,
    /https?:\/\/www\.googletagmanager\.com/,
    /https?:\/\/connect\.facebook\.net/,
    /https?:\/\/([^./]+\.)*quora\.com/,
    /https?:\/\/([^./]+\.)*firstpromoter\.com/,
    /https?:\/\/www\.clarity\.ms/
  ]
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
})
