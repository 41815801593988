import type { ImageProps } from 'next/image'
import type { NextSeoProps } from 'next-seo'
import type { LinkTypes, ModifiableLinkTypes, ParamModification, SpecificMapWithDefault } from './types'

import nexti18nextConfig from '../next-i18next.config'
import withDefault from './util/with-default'

// ANALYTICS

export const COOKIE_NAME = process.env.NODE_ENV === 'development'
  ? 'outwrite_auth_development'
  : 'outwrite_auth'
export const COOKIE_DOMAIN = process.env.NODE_ENV === 'development' ? 'localhost' : '.outwrite.com'
export const COOKIE_UTM_TAGS_KEY = 'outwrite_utm_tags'
export const LENGTH_OF_UTM_COOKIE_EXPIRATION_DAYS = 30

export const FB_PIXEL_ID = process.env.NEXT_PUBLIC_FB_PIXEL_ID ?? ''

export const UA_TRACKING_ID = process.env.NEXT_PUBLIC_UA_TRACKING_ID ?? ''
export const GA4_TRACKING_ID = process.env.NEXT_PUBLIC_GA4_TRACKING_ID ?? ''
export const AW_CONVERSION_ID = process.env.NEXT_PUBLIC_AW_CONVERSION_ID ?? ''
export const OPTIMIZE_ID = process.env.NEXT_PUBLIC_OPTIMIZE_ID ?? ''

export const AMPLITUDE_ID = process.env.NEXT_PUBLIC_AMPLITUDE_ID ?? ''

export const QUORA_PIXEL_ID = process.env.NEXT_PUBLIC_QUORA_PIXEL_ID ?? ''

export const FIRST_PROMOTER_ID = process.env.NEXT_PUBLIC_FIRST_PROMOTER ?? ''

export const MICROSOFT_CLARITY_ID = process.env.NEXT_PUBLIC_MICROSOFT_CLARITY_ID ?? ''

export const GTAG_CONFIG_OPTIONS = { optimize_id: OPTIMIZE_ID }

// LINKS

export const SITE_URL = process.env.NEXT_PUBLIC_SITE_URL ?? (
  process.env.NEXT_PUBLIC_VERCEL_URL
    // Need to add protocol since VERCEL_URL does not include
    ? `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`
    : 'http://127.0.0.1:8080'
)
export const APP_URL = 'https://app.outwrite.com'
export const API_URL = 'https://api.outwrite.com'
// Internal links
export const ABOUT_PATHNAME = '/about'
export const CAREERS_PATHNAME = '/careers'
export const CHROME_PATHNAME = '/chrome'
export const GOOGLE_DOCS_PATHNAME = '/docs'
export const EDGE_PATHNAME = '/edge'
export const ESPANOL_PATHNAME = '/espanol'
export const FAQ_PATHNAME = '/faq'
export const FRANCAIS_PATHNAME = '/francais'
export const GRADEPROOF_PATHNAME = '/gradeproof'
export const GRAMMAR_PATHNAME = '/grammar'
export const INVITE_PATHNAME = '/invite'
export const VS_GRAMMARLY_PATHNAME = '/outwrite-vs-grammarly'
export const VS_QUILLBOT_PATHNAME = '/outwrite-vs-quillbot'
export const PASSIVE_VOICE_PATHNAME = '/passive-voice'
export const PLAGIARISM_CHECKER_PATHNAME = '/plagiarism-checker'
export const PRICING_PATHNAME = '/pricing'
export const PRIVACY_PATHNAME = '/privacy'
export const PRO_PATHNAME = '/pro'
export const READABILITY_PATHNAME = '/readability'
export const REVIEWS_PATHNAME = '/reviews'
export const TEAMS_PATHNAME = '/teams'
export const TERMS_PATHNAME = '/terms'
export const TERMS_REFERRALS_PATHNAME = '/terms/referrals'
export const MS_WORD_PATHNAME = '/word'

export const PARAPHRASING_PATHNAME = '/paraphrasing'
export const CASUAL_PATHNAME = '/paraphrasing/casual'
export const EXPAND_PATHNAME = '/paraphrasing/expand'
export const FORMAL_PATHNAME = '/paraphrasing/formal'
export const IMPROVE_PATHNAME = '/paraphrasing/improve'
export const RESTRUCTURE_PATHNAME = '/paraphrasing/restructure'
export const SHORTEN_PATHNAME = '/paraphrasing/shorten'

// Other link types
export const HELP_EMAIL_LINK: LinkTypes = { exthref: 'mailto:help@outwrite.com' }
export const MEDIA_EMAIL_LINK: LinkTypes = { exthref: 'mailto:media@outwrite.com' }

// Localized link types
export const APP_LINKS_BY_LOCALE: SpecificMapWithDefault<string, LinkTypes> = {
  es: { apphref: '/?lng=es' },
  fr: { apphref: '/?lng=fr' },
  '*': { apphref: '/' }
}
export const PLANS_LINKS_BY_LOCALE: SpecificMapWithDefault<string, LinkTypes> = {
  es: { apphref: '/home/account/settings?lng=es&payments=plans' },
  fr: { apphref: '/home/account/settings?lng=fr&payments=plans' },
  '*': { apphref: '/home/account/settings?payments=plans' }
}
export const REGISTER_LINKS_BY_LOCALE: SpecificMapWithDefault<
string,
ModifiableLinkTypes<ParamModification>
> = {
  es: {
    apphref: '/auth/register?lng=es',
    modify: { params: (params) => ({ apphref: `/auth/register?lng=es&${params}` }) }
  },
  fr: {
    apphref: '/auth/register?lng=fr',
    modify: { params: (params) => ({ apphref: `/auth/register?lng=fr&${params}` }) }
  },
  '*': {
    apphref: '/auth/register',
    modify: { params: (params) => ({ apphref: `/auth/register?${params}` }) }
  }
}
export const SIGN_IN_LINKS_BY_LOCALE: SpecificMapWithDefault<string, LinkTypes> = {
  es: { apphref: '/auth/login?lng=es' },
  fr: { apphref: '/auth/login?lng=fr' },
  '*': { apphref: '/auth/login' }
}
export const BLOG_LINKS_BY_LOCALE: SpecificMapWithDefault<string, LinkTypes> = {
  fr: { exthref: 'https://fr.blog.outwrite.com' },
  '*': { exthref: 'https://blog.outwrite.com' }
}
export const SUPPORT_LINKS_BY_LOCALE: SpecificMapWithDefault<string, LinkTypes> = {
  fr: { exthref: 'https://fr.support.outwrite.com' },
  '*': { exthref: 'https://support.outwrite.com' }
}
export const PRO_SUPPORT_LINKS_BY_LOCALE: SpecificMapWithDefault<string, LinkTypes> = {
  fr: { exthref: 'https://fr.support.outwrite.com/article/64-pro-features' },
  '*': { exthref: 'https://support.outwrite.com/article/64-pro-features' }
}
export const CHROME_EXT_LINKS_BY_LOCALE: SpecificMapWithDefault<string, LinkTypes> = {
  es: { exthref: 'https://chrome.google.com/webstore/detail/outwrite-for-chrome/jldbdlmljpigglecmeclifcdhgbjbakk?hl=es' },
  fr: { exthref: 'https://chrome.google.com/webstore/detail/outwrite-for-chrome/jldbdlmljpigglecmeclifcdhgbjbakk?hl=fr' },
  '*': { exthref: 'https://chrome.google.com/webstore/detail/outwrite-for-chrome/jldbdlmljpigglecmeclifcdhgbjbakk' }
}
export const EDGE_EXT_LINKS_BY_LOCALE: SpecificMapWithDefault<string, LinkTypes> = {
  es: { exthref: 'https://microsoftedge.microsoft.com/addons/detail/outwrite-for-edge/fcdbkdengaelkbaafmlalbedmjnmjapa?hl=es' },
  fr: { exthref: 'https://microsoftedge.microsoft.com/addons/detail/outwrite-for-edge/fcdbkdengaelkbaafmlalbedmjnmjapa?hl=fr' },
  '*': { exthref: 'https://microsoftedge.microsoft.com/addons/detail/outwrite-for-edge/fcdbkdengaelkbaafmlalbedmjnmjapa' }
}
export const GOOGLE_DOCS_ADDIN_LINKS_BY_LOCALE: SpecificMapWithDefault<string, LinkTypes> = {
  es: { exthref: 'https://gsuite.google.com/marketplace/app/outwrite_previously_gradeproof/1017605304002?hl=es' },
  fr: { exthref: 'https://gsuite.google.com/marketplace/app/outwrite_previously_gradeproof/1017605304002?hl=fr' },
  '*': { exthref: 'https://gsuite.google.com/marketplace/app/outwrite_previously_gradeproof/1017605304002' }
}
export const CAPTERRA_LINKS_BY_LOCALE: SpecificMapWithDefault<string, LinkTypes> = {
  fr: { exthref: 'https://www.capterra.fr/software/219935/outwrite' },
  '*': { exthref: 'https://www.capterra.com.au/software/219935/outwrite' }
}

// { pathname: { locale: { link }}}
const CTA_LINK: SpecificMapWithDefault<string | 'platform' | 'payments', SpecificMapWithDefault<string, LinkTypes>> = {
  [ABOUT_PATHNAME]: { '*': { href: CAREERS_PATHNAME } },
  [CAREERS_PATHNAME]: { '*': { exthref: 'https://outwrite.notion.site/Careers-Outwrite-17f6dd928c0a446e900e9d554460756a' } },
  [CHROME_PATHNAME]: CHROME_EXT_LINKS_BY_LOCALE,
  [EDGE_PATHNAME]: EDGE_EXT_LINKS_BY_LOCALE,
  [GOOGLE_DOCS_PATHNAME]: GOOGLE_DOCS_ADDIN_LINKS_BY_LOCALE,
  [MS_WORD_PATHNAME]: { '*': { exthref: 'https://appsource.microsoft.com/en-us/product/office/WA104381714' } },
  [ESPANOL_PATHNAME]: {
    es: { apphref: '?lng=es&registerLng=es' },
    fr: { apphref: '?lng=fr&registerLng=es' },
    '*': { apphref: '?registerLng=es' }
  },
  [FRANCAIS_PATHNAME]: {
    es: { apphref: '?lng=es&registerLng=fr' },
    fr: { apphref: '?lng=fr&registerLng=fr' },
    '*': { apphref: '?registerLng=fr' }
  },
  '*': APP_LINKS_BY_LOCALE,
  // not pathname but just section of ui
  platform: CHROME_EXT_LINKS_BY_LOCALE,
  payments: PLANS_LINKS_BY_LOCALE
}
export const pageSpecificCtaLink = (pathname: string, locale?: string): LinkTypes => (
  withDefault(withDefault(CTA_LINK, pathname), locale)
)

// UI CONSTANTS

export const TABLET_WIDTH_PX = 992
export const MOBILE_WIDTH_PX = 600
export const STICKY_HEADER_ZINDEX = 1
export const MOBILE_HEADER_MENU_ZINDEX = 1

export const DEFAULT_NEXT_IMG_PROPS: Partial<ImageProps> = {
  lazyBoundary: '400px'
}

// LOCALES

export const SUPPORTED_LOCALES = [
  { locale: 'en', name: 'English' },
  { locale: 'fr', name: 'Français' },
  { locale: 'es', name: 'Español' }
]

// SEO

/**
 * Page specific default SEO
 * @param pathname The pathname of the current page
 * @returns NextSEO props
 */
const { i18n } = nexti18nextConfig
const OPEN_GRAPH_IMG: SpecificMapWithDefault<string, SpecificMapWithDefault<string, string>> = {
  [ABOUT_PATHNAME]: { '*': 'en/about.png' },
  [CAREERS_PATHNAME]: { '*': 'en/careers.png' },
  [CHROME_PATHNAME]: { '*': 'en/chrome.png' },
  [GOOGLE_DOCS_PATHNAME]: { '*': 'en/docs.png' },
  [EDGE_PATHNAME]: { '*': 'en/edge.png' },
  [ESPANOL_PATHNAME]: {
    en: 'en/espanol.png',
    '*': 'es/espanol.png' // Default to native page
  },
  [FRANCAIS_PATHNAME]: {
    en: 'en/francais.png',
    '*': 'fr/francais.png' // Default to native page
  },
  [INVITE_PATHNAME]: { '*': 'en/invite.png' },
  [VS_GRAMMARLY_PATHNAME]: { '*': 'en/outwrite-vs-grammarly.png' },
  [VS_QUILLBOT_PATHNAME]: { '*': 'en/outwrite-vs-quillbot.png' },
  [PARAPHRASING_PATHNAME]: { '*': 'en/paraphrasing.png' },
  [CASUAL_PATHNAME]: { '*': 'en/paraphrasing/casual.png' },
  [EXPAND_PATHNAME]: { '*': 'en/paraphrasing/expand.png' },
  [FORMAL_PATHNAME]: { '*': 'en/paraphrasing/formal.png' },
  [IMPROVE_PATHNAME]: { '*': 'en/paraphrasing.png' },
  [RESTRUCTURE_PATHNAME]: { '*': 'en/paraphrasing/restructure.png' },
  [SHORTEN_PATHNAME]: { '*': 'en/paraphrasing/shorten.png' },
  [PASSIVE_VOICE_PATHNAME]: { '*': 'en/passive-voice.png' },
  [PLAGIARISM_CHECKER_PATHNAME]: { '*': 'en/plagiarism-checker.png' },
  [PRO_PATHNAME]: { '*': 'en/pro.png' },
  [READABILITY_PATHNAME]: { '*': 'en/readability.png' },
  [REVIEWS_PATHNAME]: { '*': 'en/reviews.png' },
  [TEAMS_PATHNAME]: { '*': 'en/teams.png' },
  [MS_WORD_PATHNAME]: { '*': 'en/word.png' },
  '*': { '*': 'en/home.png' }
}
export const defaultPageSpecificSEO = (pathname: string, locale: string = 'en'): NextSeoProps => {
  const IMG_SRC = withDefault(withDefault(OPEN_GRAPH_IMG, pathname), locale)
  return {
    openGraph: {
      images: [
        { url: `${SITE_URL}/assets/open-graph/${IMG_SRC}`, width: 1200, height: 630 }
      ]
    },
    languageAlternates: i18n.locales.map((loc: string) => ({
      href: `${SITE_URL}${loc === i18n.defaultLocale ? '' : `/${loc}`}${pathname}`,
      hrefLang: loc
    })),
    canonical: `${SITE_URL}${locale === i18n.defaultLocale ? '' : `/${locale}`}${pathname}`
  }
}
