import type React from 'react'

import { css, Global, useTheme } from '@emotion/react'
import { DEFAULT } from '../font'

const GlobalStyles: React.FC = () => {
  const theme = useTheme()
  return (
    <Global
    styles={css`
      body {
        margin: 0;
        min-height: 100%;
        background-color: ${theme.background};
        font-family: 'Open Sans',sans-serif;
        color: ${theme.textColor};
        ${DEFAULT[300]}
      }

      * {
        box-sizing: border-box;
      }

      h1,h2,h3 {
        font-family: 'Abhaya Libre','Times New Roman',sans-serif;
      }
    `}
  />
  )
}

export default GlobalStyles
