import { css } from '@emotion/react'

// TODO: to move these into themes!!

/**
 * ALL have
 * font-family: Abhaya Libre
 * font-style: normal;
 * font-weight: 700
 * letter-spacing: 0em;
 */
export const COMMON_TITLE_CSS = css`
  font-family: 'Abhaya Libre','Times New Roman',sans-serif;
  font-weight: 700;
`

export const TITLES = {
  1100: css`
    font-size: 72px;
    line-height: 72px;
  `,
  1000: css`
    font-size: 60px;
    line-height: 62px;
  `,
  900: css`
    font-size: 48px;
    line-height: 52px;
  `,
  800: css`
    font-size: 36px;
    line-height: 40px;
  `,
  700: css`
    font-size: 30px;
    line-height: 30px;
  `,
  600: css`
    font-size: 24px;
    line-height: 24px;
  `,
  500: css`
    font-size: 20px;
    line-height: 20px;
  `,
  400: css`
    font-size: 16px;
    line-height: 16px;
  `
}

/**
 * All have
 * font-family: Open Sans
 * font-weight: 400
 * font-style: normal
 * letter-spacing: 0em
 */
export const COMMON_DEFAULT_CSS = css`
  font-family: 'Open Sans',sans-serif;
  font-weight: 400;
`

export const DEFAULT = {
  1100: css`
    font-size: 72px;
    line-height: 72px;
  `,
  1000: css`
    font-size: 60px;
    line-height: 62px;
  `,
  900: css`
    font-size: 48px;
    line-height: 52px;
  `,
  800: css`
    font-size: 36px;
    line-height: 40px;
  `,
  700: css`
    font-size: 30px;
    line-height: 36px;
  `,
  600: css`
    font-size: 24px;
    line-height: 32px;
  `,
  500: css`
    font-size: 20px;
    line-height: 28px;
  `,
  400: css`
    font-size: 18px;
    line-height: 26px;
  `,
  300: css`
    font-size: 16px;
    line-height: 24px;
  `,
  200: css`
    font-size: 14px;
    line-height: 21px;
  `,
  100: css`
    font-size: 12px;
    line-height: 18px;
  `,
  0: css`
    font-size: 10px;
    line-height: 14px;
  `
}
