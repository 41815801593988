// Type imports
import type React from 'react'
import type { AppProps } from 'next/app'

// Polyfills
import 'intersection-observer' // TODO:? maybe import only if IntersectionObserver is not found?

// External imports
import Script from 'next/script'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

import { appWithTranslation } from 'next-i18next'
import { DefaultSeo } from 'next-seo'

import Cookies from 'js-cookie'
import amplitude from 'amplitude-js'

import { ThemeProvider } from '@emotion/react'
import themes from '@outwrite/themes'

// Misc
import analytics from '../util/analytics'
import saveUtmTags from '../util/save-utm-tags'

import SEO from '../../next-seo.config'
import GlobalStyles from '../styles/GlobalStyles'

import {
  AMPLITUDE_ID,
  COOKIE_DOMAIN,
  COOKIE_NAME,
  FB_PIXEL_ID,
  QUORA_PIXEL_ID,
  UA_TRACKING_ID,
  FIRST_PROMOTER_ID,
  MICROSOFT_CLARITY_ID
} from '../constants'

const CLARITY_IDENTITY_PROPERTIES = [
  'registrationPlatform',
  'platforms',
  'subscriptionState',
  'category',
  'language'
]

const App: React.FC<AppProps & { err?: any }> = ({ Component, pageProps, err }) => {
  const router = useRouter()

  useEffect(() => {
    let user: any // TODO: type this better
    try {
      const userObj = Cookies.get(COOKIE_NAME)
      if (userObj) { user = JSON.parse(decodeURIComponent(userObj)) }
    } catch (error) {
      console.error(error)
    }
    user?.user && analytics.identify(user.user)

    const userId = user?.user?.id

    // Initialize facebook pixel here to initialize with user data
    if (window.fbq) {
      if (userId) {
        // Check if this needs to be hashed? & is the same format as webapp external_id
        window.fbq('init', FB_PIXEL_ID, { external_id: userId, uid: userId })
      } else {
        window.fbq('init', FB_PIXEL_ID)
      }
    }

    const amplitudeDeviceCookie = Cookies.get('amplitudeDeviceId')
    const amplitudeDeviceObj = amplitudeDeviceCookie && decodeURIComponent(amplitudeDeviceCookie)
    amplitude.getInstance().init(
      AMPLITUDE_ID,
      userId,
      {
        domain: COOKIE_DOMAIN,
        includeReferrer: true,
        includeUtm: true,
        deviceId: amplitudeDeviceObj
      },
      (instance) => {
        if (instance.options.deviceId) {
          Cookies.set('amplitudeDeviceId', instance.options.deviceId, { expires: 30, domain: COOKIE_DOMAIN })
        }
      }
    )

    // Populate user identification for analytics
    if (typeof user?.user === 'object' && userId) {
      // TODO:? do we need to set user properties for amplitude?

      // Microsoft Clarity
      const clarity = window.clarity
      if (clarity) {
        clarity('identify', userId)

        CLARITY_IDENTITY_PROPERTIES.forEach((key) => {
          const propertyValue = user.user[key] ?? ''
          if (!propertyValue) return
          clarity('set', key, propertyValue)
        })
      }
    }
  }, [])

  useEffect(() => {
    // Save the UTM tags of the session in a cookie
    saveUtmTags(router.query)
  }, [router.query])

  useEffect(() => {
    const handleRouteChange = (url: string): void => analytics.pageview(url)

    // This pageview triggers during the initial load.
    handleRouteChange(router.asPath)

    router.events.on('routeChangeComplete', handleRouteChange)
    return (): void => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  return (
    <>
      {/* Global Site Code Pixel - Facebook Pixel */}
      <Script
        id='fpixel-init'
        strategy='afterInteractive'
        dangerouslySetInnerHTML={{
          __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
          `
        }}
      />

      {/* Global Site Tag (gtag.js) - Google Analytics */}
      <Script
        strategy='afterInteractive'
        src={`https://www.googletagmanager.com/gtag/js?id=${UA_TRACKING_ID}`}
      />
      <Script
        id='gtag-init'
        strategy='afterInteractive'
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
          `
        }}
      />

      {/* Quora Pixel */}
      <Script
        id='qp-init'
        strategy='afterInteractive'
        dangerouslySetInnerHTML={{
          __html: `
          !function(q,e,v,n,t,s){if(q.qp) return; n=q.qp=function(){n.qp?n.qp.apply(n,arguments):n.queue.push(arguments);}; n.queue=[];t=document.createElement(e);t.async=!0;t.src=v; s=document.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t,s);}(window, 'script', 'https://a.quora.com/qevents.js');
          qp('init', '${QUORA_PIXEL_ID}');
          qp('track', 'ViewContent')
          `
        }}
      />
      <noscript><img height="1" width="1" style={{ display: 'none' }} src={`https://q.quora.com/_/ad/${QUORA_PIXEL_ID}/pixel?tag=ViewContent&noscript=1`} /></noscript>

      {/* First-Promoter */}
      <Script
        id='fp-init'
        strategy='afterInteractive'
        dangerouslySetInnerHTML={{
          __html: `
          (function(){var t=document.createElement("script");t.type="text/javascript",t.async=!0,t.src='https://cdn.firstpromoter.com/fprom.js',t.onload=t.onreadystatechange=function(){var t=this.readyState;if(!t||"complete"==t||"loaded"==t)try{$FPROM.init("${FIRST_PROMOTER_ID}","${COOKIE_DOMAIN}")}catch(t){}};var e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(t,e)})();
          `
        }}
      />

      {/* Microsoft Clarity */}
      <Script
        id='microsoft-clarity-init'
        strategy='afterInteractive'
        dangerouslySetInnerHTML={{
          __html: `
          (function(c,l,a,r,i,t,y){
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
          })(window, document, "clarity", "script", "${MICROSOFT_CLARITY_ID}");
          `
        }}
      />

      {/* Default SEO */}
      <DefaultSeo {...SEO} />

      {/* Emotion theme */}
      <ThemeProvider theme={themes.light}>
        <GlobalStyles />
        {/* Workaround for https://github.com/vercel/next.js/issues/8592 */}
        <Component {...pageProps} err={err} />
      </ThemeProvider>
    </>
  )
}

export default appWithTranslation(App)
