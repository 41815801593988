/** @type {import('next-seo').DefaultSeoProps} */
module.exports = {
  openGraph: {
    type: 'website'
  },
  twitter: {
    cardType: 'summary_large_image'
  },
  additionalLinkTags: [
    { rel: 'apple-touch-icon', sizes: '180x180',  href: '/apple-touch-icon.png' },
    { rel: 'icon', type: 'image/png', sizes: '32x32', href: '/favicon-32x32.png' },
    { rel: 'icon', type: 'image/png', sizes: '16x16', href: '/favicon-16x16.png' },
    { rel: 'manifest', href: '/site.webmanifest' },
    { rel: 'mask-icon', href: '/safari-pinned-tab.svg', color: '#1b1b1b' }
  ],
  additionalMetaTags: [
    { name: 'msapplication-TitleColor', content: '#603cba' },
    { name: 'theme-color', content: '#fff8e0' }
  ]
}
