import { ParsedUrlQuery } from 'querystring'
import Cookies from 'js-cookie'
import {
  COOKIE_UTM_TAGS_KEY,
  LENGTH_OF_UTM_COOKIE_EXPIRATION_DAYS,
  COOKIE_DOMAIN
} from '../constants'

const TAGS = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content'
]

/**
 * Save the UTM tags of the current url to a cookie.
 */
const saveUtmTags = (query: ParsedUrlQuery): void => {
  /**
   * Only process if the query contain utm tags
   */
  const hasUtmTags = TAGS.some((tag) => !!query[tag])

  if (hasUtmTags) {
    const utmTags = TAGS.reduce<Record<string, string>>((tags, tag) => {
      // Set the tag value to the tags object
      tags[tag] = (query[tag] as string) ?? ''
      return tags
    }, {})

    Cookies.set(
      COOKIE_UTM_TAGS_KEY,
      JSON.stringify(utmTags),
      {
        expires: LENGTH_OF_UTM_COOKIE_EXPIRATION_DAYS,
        domain: COOKIE_DOMAIN
      }
    )
  }
}

export default saveUtmTags
