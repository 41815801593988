const path = require('path')

/** @type {import('next-i18next').UserConfig} */
module.exports = {
  i18n: {
    defaultLocale: 'en',
    locales: ['en', 'fr', 'es']
  },
  returnObjects: true,
  reloadOnPrerender: true,
  // https://github.com/isaachinman/next-i18next/issues/1141
  // "When deploying to Vercel, you must use path.resolve to force the Vercel
  // serverless build to include your locales directory."
  localePath: path.resolve('./public/locales')
}
