
import amplitude from 'amplitude-js'
import querystring, { ParsedQuery } from 'query-string'
import pick from 'lodash.pick'

import withDefault from './with-default'

import {
  ABOUT_PATHNAME,
  AW_CONVERSION_ID,
  CHROME_PATHNAME,
  EDGE_PATHNAME,
  GA4_TRACKING_ID,
  GOOGLE_DOCS_PATHNAME,
  GTAG_CONFIG_OPTIONS,
  INVITE_PATHNAME,
  MS_WORD_PATHNAME,
  SITE_URL,
  UA_TRACKING_ID
} from '../constants'
import { SpecificMapWithDefault } from '../types'

const DEBUG = process.env.NODE_ENV === 'development'

type QuoraPixel = (event: 'track', type: 'Generic') => void
type MicrosoftClarity = (method: 'set' | 'identify', args1: string, ...args: Array<string | string[]>) => void
declare global {
  interface Window {
    qp: QuoraPixel
    clarity?: MicrosoftClarity
  }
}

const UTM_CAMPAIGN_QUERY_KEY = 'utm_campaign'
const UTM_CONTENT_QUERY_KEY = 'utm_content'
const UTM_MEDIUM_QUERY_KEY = 'utm_media'
const UTM_SOURCE_QUERY_KEY = 'utm_source'

type UTMInfo = 'content' | 'medium' | 'campaign' | 'source'

export type AnalyticsTarget = 'amplitude' | 'googleAnalytics' | 'quoraPixel' | 'facebookPixel'
export type AnalyticsTargetConfig = Record<AnalyticsTarget, boolean>

export const DEFAULT_TARGET_CONFIG: AnalyticsTargetConfig = {
  amplitude: true,
  googleAnalytics: true,
  quoraPixel: true,
  facebookPixel: true
}

interface AnalyticsTargetConfigWithParams extends Omit<AnalyticsTargetConfig, 'googleAnalytics' | 'facebookPixel'> {
  googleAnalytics: (
    boolean |
    Gtag.ControlParams |
    Gtag.EventParams |
    Gtag.CustomParams
  )
  facebookPixel: (
    boolean |
    facebook.Pixel.CustomParameters // If needed, add other kinds of params..
  )
}

const AMPLITUDE_PAGE_NAME_LOOKUP_TABLE = {
  '/': 'Home',
  [ABOUT_PATHNAME]: 'About Us',
  [CHROME_PATHNAME]: 'Chrome',
  [GOOGLE_DOCS_PATHNAME]: 'Google Docs'
}
type AmplitudePageRoute = keyof (typeof AMPLITUDE_PAGE_NAME_LOOKUP_TABLE)

interface EventConfig {
  amplitude: string
  googleAnalytics: {
    ua: { action: string, category: string }
    ga4?: string // By default just uses ua.category
  }
  facebookPixel: string
}

export type ClickId = (
  'header-cta-get-started' |
  'hero-cta' |
  'pricing-cta' |
  'bottom-cta' |
  'add-to-chrome' |
  'sign-in' |
  'support'
)

const CLICK_ID_LOOKUP_TABLE: Record<ClickId, SpecificMapWithDefault<string, EventConfig>> = {
  'header-cta-get-started': {
    '*': {
      amplitude: 'Get Started CTA Header clicked',
      googleAnalytics: { ua: { action: 'clicked', category: 'Get Started CTA Header' } },
      facebookPixel: 'GetStarted-Header' // From "Facebook Pixel - Get Started CTA clicked (Marketing Site)"
      // Alternatively... SignupButton - "Facebook Pixel - Signup Link clicked (Marketing Site)" ?
    }
  },
  'hero-cta': {
    [INVITE_PATHNAME]: {
      amplitude: 'Accept Invitation CTA clicked',
      googleAnalytics: { ua: { action: 'clicked', category: 'Accept Invitation CTA' } },
      facebookPixel: 'AcceptInvitation-Hero'
    },
    [CHROME_PATHNAME]: {
      amplitude: 'Add to Chrome CTA clicked',
      googleAnalytics: { ua: { action: 'clicked', category: 'Add to Chrome CTA' } },
      facebookPixel: 'AddToChrome-Hero'
    },
    [EDGE_PATHNAME]: {
      amplitude: 'Add to Edge CTA clicked',
      googleAnalytics: { ua: { action: 'clicked', category: 'Add to Edge CTA' } },
      facebookPixel: 'AddToEdge-Hero'
    },
    [GOOGLE_DOCS_PATHNAME]: {
      amplitude: 'Add to Docs CTA clicked',
      googleAnalytics: { ua: { action: 'clicked', category: 'Add to Docs CTA' } },
      facebookPixel: 'AddToDocs-Hero'
    },
    [MS_WORD_PATHNAME]: {
      amplitude: 'Add to Word CTA clicked',
      googleAnalytics: { ua: { action: 'clicked', category: 'Add to Word CTA' } },
      facebookPixel: 'AddToWord-Hero'
    },
    '*': {
      amplitude: 'Get Started CTA Hero clicked',
      googleAnalytics: { ua: { action: 'clicked', category: 'Get Started CTA Hero' } },
      facebookPixel: 'GetStarted-Hero'
    }
  },
  'pricing-cta': {
    '*': {
      amplitude: 'Get Started clicked',
      googleAnalytics: { ua: { action: 'clicked', category: 'Get Started' } },
      facebookPixel: 'GetStarted'
    }
  },
  'bottom-cta': {
    '*': {
      amplitude: 'Get Started CTA Bottom clicked',
      googleAnalytics: { ua: { action: 'clicked', category: 'Get Started CTA Bottom' } },
      facebookPixel: 'GetStarted-Bottom'
    }
    // Alternatively, if we want the same as before
    // for about route Marketing - Get Started - About Us (CTA) click - get-started-about-cta
    // [ABOUT_PATHNAME]: {
    //   amplitude: 'Get started About CTA clicked', { href: '/about' },
    //   googleAnalytics: { ua: {
    //     action: 'clicked',
    //     category: 'Get Started CTA',
    //     label: 'get-started-about-cta
    //   }}
    // }
    // for home route Marketing - Get Started - Home (CTA) click - get-started-home-cta
    // '/': {
    //   amplitude: 'Get started Home CTA clicked', { href: '/' },
    //   googleAnalytics: { ua: {
    //     action: 'clicked',
    //     category: 'Get Started CTA',
    //     label: 'get-started-home-cta'
    //   }}
    // }
  },
  'add-to-chrome': {
    '*': {
      amplitude: 'Add to Chrome clicked',
      googleAnalytics: { ua: { action: 'clicked', category: 'Add to Chrome' } },
      facebookPixel: 'AddToChrome'
    }
  },
  'sign-in': {
    '*': {
      amplitude: 'Sign in clicked',
      googleAnalytics: { ua: { action: 'clicked', category: 'Sign in' } },
      facebookPixel: 'LoginLink' // Same as before - "Facebook Pixel - Login Link clicked (Marketing Site)"
    }
  },
  support: {
    '*': {
      amplitude: 'Support clicked',
      googleAnalytics: { ua: { action: 'clicked', category: 'Support' } },
      facebookPixel: 'SupportLinkClicked' // Same as before - "Facebook Pixel - Support link clicked (Marketing site)"
    }
  }
}

const getFirstQueryMatch = (query: ParsedQuery<string>, key: string): string | undefined => {
  const value = query[key]
  if (!value) return
  return typeof value === 'string' ? value : value[0]
}

const isAnalyticsEnabled = (origin: string): boolean => (
  [SITE_URL, 'http://localhost:8080'].includes(origin)
)

interface Analytics {
  identify: (user: any) => void // TODO: type better
  pageview: (url: string, targetConfig?: AnalyticsTargetConfig) => void
  trackClick: (
    clickId: ClickId,
    pathname: string,
    targetConfig?: AnalyticsTargetConfigWithParams
  ) => void
}

const analytics = ((): Analytics => {
  let user: any | undefined

  return {
    identify: (userObj): void => {
      DEBUG && console.log('[USER IDENTITY]', userObj)
      if (userObj.id) {
        user = userObj
      }
    },

    /**
     * Track page view
     * @param url Url of current page view
     * @param targetConfig By default, sends to all targets (amplitude, ga, quora, facebookPixel)
     */
    pageview: (
      url: string,
      targetConfig: AnalyticsTargetConfig = DEFAULT_TARGET_CONFIG
    ): void => {
      if (!isAnalyticsEnabled(window.location.origin)) return

      DEBUG && console.log('[PAGE_VIEW]', { url, targetConfig })

      const { query, url: pathname } = querystring.parseUrl(url)

      const utmInfo: Partial<Record<UTMInfo, string>> = Object.entries({
        campaign: UTM_CAMPAIGN_QUERY_KEY,
        medium: UTM_MEDIUM_QUERY_KEY,
        content: UTM_CONTENT_QUERY_KEY,
        source: UTM_SOURCE_QUERY_KEY
      }).reduce<{[s: string]: string}>((acc, [key, queryKey]) => {
        const match = getFirstQueryMatch(query, queryKey)
        if (match) acc[key] = match
        return acc
      }, {})

      if (!!window.gtag && targetConfig.googleAnalytics) {
        // https://triodox.com/blog/utm-tagging-in-universal-analytics-and-google-analytics-4ga4/
        // This blog says that is UTM-tags are automatically applied
        // to Google Analytics 4 as Event Parameters??
        // But im not too sure about UA...
        // So just in case, im just gonna add it manually here
        const MAP: Record<UTMInfo, string> = {
          campaign: 'campaignName',
          medium: 'campaignMedium',
          content: 'campaignContent',
          source: 'campaignSource'
        }

        let configOpts: { [s: string]: string | boolean } = (Object.keys(utmInfo) as UTMInfo[])
          .reduce((acc, infoKey) => ({
            ...acc,
            [MAP[infoKey]]: utmInfo[infoKey]
          }), GTAG_CONFIG_OPTIONS)

        // Add any user identification
        if (user?.id) {
          configOpts = [
            { key: 'userSubscriptionState', userKey: 'subscriptionState' },
            { key: 'userCategory', userKey: 'category' },
            { key: 'registrationPlatform', userKey: 'registrationPlatform' },
            { key: 'platforms', userKey: 'platforms' },
            // Not too sure what it should be...
            // gtag documentation says 'user_id' but in google tag we have userId
            // Just adding both to be safe haha
            { key: 'userId', userKey: 'id' },
            { key: 'user_id', userKey: 'id' }
          ].reduce(
            (acc, prop) => ({ ...acc, [prop.key]: user[prop.userKey] ?? '' }),
            configOpts
          )
        }

        if (DEBUG) configOpts.debug_mode = true

        window.gtag('config', UA_TRACKING_ID, configOpts)
        window.gtag('config', GA4_TRACKING_ID, configOpts)
        window.gtag('config', AW_CONVERSION_ID, configOpts)
      }

      if (!!window.fbq && targetConfig.facebookPixel) {
        // TODO: add user properties??
        window.fbq('track', 'PageView')
      }
      if (!!window.qp && targetConfig.quoraPixel) {
        window.qp('track', 'Generic')
      }
      if (targetConfig.amplitude) {
        amplitude.getInstance().logEvent(`Visited ${AMPLITUDE_PAGE_NAME_LOOKUP_TABLE[pathname as AmplitudePageRoute] ?? pathname} page`)

        // Log any additional utm info...
        const MAP: Record<UTMInfo, string> = {
          campaign: 'utm_campaign',
          medium: 'utm_medium',
          content: 'utm_content',
          source: 'utm_source'
        }
        const info = (Object.keys(utmInfo) as UTMInfo[]).reduce((acc, infoKey) => ({
          ...acc,
          [MAP[infoKey]]: utmInfo[infoKey]
        }), GTAG_CONFIG_OPTIONS)

        if (Object.keys(info).length > 0) {
          amplitude.getInstance().logEvent('utm', info)
        }
      }
    },

    /**
     * Using click id, sends corresponding event
     * @param clickId Id corresponding to event
     * @param pathname Pathname of route
     * @param targetConfig Analytics target config with additional event parameters
     * @returns void
     */
    trackClick: (
      clickId: ClickId,
      pathname: string,
      targetConfig: AnalyticsTargetConfigWithParams = DEFAULT_TARGET_CONFIG
    ): void => {
      if (!isAnalyticsEnabled(window.location.origin)) return

      DEBUG && console.log('[TRACK_CLICK]', { clickId, pathname, targetConfig })

      if (!CLICK_ID_LOOKUP_TABLE[clickId]) {
        console.error('Could not find valid click id')
        return
      }

      const action = withDefault(CLICK_ID_LOOKUP_TABLE[clickId], pathname)

      if (!!window.gtag && targetConfig.googleAnalytics) {
        const { action: eventName, category } = action.googleAnalytics.ua
        const ga4EventName = action.googleAnalytics.ga4 ?? category
        if (typeof targetConfig.googleAnalytics === 'boolean') {
          window.gtag('event', eventName, { event_category: category })
          // Also send to ga4 tracking id
          window.gtag('event', ga4EventName, { send_to: GA4_TRACKING_ID })
        } else {
          // Only send the valid universal params
          const validUAParams = pick(targetConfig.googleAnalytics, ['event_category', 'event_label', 'value'])
          window.gtag('event', eventName, { event_category: category, ...validUAParams })

          // GA4 allows all kinds of params, so send everything
          window.gtag('event', ga4EventName, { ...targetConfig.googleAnalytics, send_to: GA4_TRACKING_ID })
        }
      }

      if (!!window.fbq && targetConfig.facebookPixel) {
        if (typeof targetConfig.facebookPixel === 'boolean') {
          window.fbq('trackCustom', action.facebookPixel)
        } else {
          window.fbq('trackCustom', action.facebookPixel, targetConfig.facebookPixel)
        }
      }

      if (!!window.qp && targetConfig.quoraPixel) {
        window.qp('track', 'Generic')
      }

      if (targetConfig.amplitude) {
        amplitude.getInstance().logEvent(action.amplitude, { href: pathname })
      }
    }
  }
})()

export default analytics
